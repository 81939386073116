import React from 'react'
import './services.css'
import desktop from './../../assets/desktop_mockup.png'
import mobile from './../../assets/mobile_mockup.png'
import services_json from './services.json'

const Services = () => {
    return (
        <section id="services" className="container-fluid services_container">
            {/* DESKTOP */}
            <div className="section services_section">
                <div className="service">
                    {/* <h1 className="subtitle">Servizi Web</h1> */}
                    <img data-aos="fade-up" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease" src={desktop} alt="" />
                </div>
                <div className="service">
                    <div className="service_list">
                        {
                            services_json.desktop.map(({ icon, title, body }, index) => {
                                return (
                                    <div key={index} data-aos="fade-up" data-aos-delay={200+(index*100)} data-aos-offset="0" data-aos-easing="ease" className="service_item">
                                        <img src={"services/" + icon + ".png"} alt="" />
                                        <p className="service_item_title">{title}</p>
                                        <p className="service_item_body">{body}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {/* MOBILE */}
            {/* <div className="section services_section">
                <div className="service">
                    <div className="service_list">
                        {
                            services_json.mobile.map(({ icon, title, body }, index) => {
                                return (
                                    <div key={index} data-aos="fade-up" data-aos-delay={200+(index*100)} data-aos-offset="0" data-aos-easing="ease" className="service_item">
                                        <img src={"services/" + icon + ".png"} alt="" />
                                        <p className="service_item_title">{title}</p>
                                        <p className="service_item_body">{body}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="service">
                    <h1 className="subtitle">Servizi Mobile</h1>
                    <img data-aos="fade-up" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease" src={mobile} alt="" />
                </div>
            </div> */}
        </section>
    )
}

export default Services
