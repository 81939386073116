import { useEffect } from 'react'
import './App.css'
import aos from 'aos'
import 'aos/dist/aos.css'
import Nav from './components/nav/Nav'
import Cover from './components/cover/Cover'
import About from './components/about/About'
import Services from './components/services/Services'
import Banner from './components/banner/Banner'
import Testimonials from './components/testimonials/Testimonials'
import Contacts from './components/contacts/Contacts'
import Footer from './components/footer/Footer'

function App() {
  useEffect(() => {
    aos.init({ duration: "600" })
  }, [])

  return (
    <>
      <Nav />
      <Cover />
      <About />
      <Services />
      <Banner />
      <Testimonials />
      <Contacts />
      <Footer />
    </>
  );
}

export default App;
