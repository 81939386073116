import React from 'react'
import './nav.css'

const Nav = () => {
    return (
        <div className="nav_container">
            <div className="container">
                <div className="nav_logo">
                    <a href="#home">.mauro</a>
                </div>
                <ul>
                    <li><a href="#home">.home()</a></li>
                    <li><a href="#about">.chi sono</a></li>
                    <li><a href="#services">.servizi</a></li>
                    <li><a href="#testimonials">.recensioni</a></li>
                    <li><a href="#contacts">Contatti</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Nav
