import React, { useState } from 'react'
import './contacts.css'
import Callme from './../../assets/callme.png'
import emailjs from '@emailjs/browser';

const Contacts = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const [mailOK, setMailOK] = useState('none');
  const [mailERR, setMailERR] = useState('none');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_sfef5ym';
    const templateId = 'template_3368zop';
    const publicKey = 'Tn7GeZrxvpsO7MhFu';

    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      message: message
    }

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log(response);
        setMailOK('block');
        setTimeout(() => {
          setMailOK('none');
        }, 1500)

        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
      })
      .catch((error) => {
        console.log(error);
        setMailERR('block');
        setTimeout(() => {
          setMailERR('none');
        }, 1500)
      })
  }

  return (
    <section id="contacts" className="section container contacts_container">
      <h1 data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease" className="subtitle">Contatti
        <p data-aos="fade-up" data-aos-delay="100" data-aos-offset="0" data-aos-easing="ease">Sempre a tua disposizione!</p>
      </h1>
      {/* <div className="contacts_avatar">
        <img src={Callme} alt="" />
      </div> */}
      <div data-aos="fade-in" data-aos-delay="300" data-aos-offset="0" data-aos-easing="ease" className="contacts_form">

        <form onSubmit={handleSubmit}>
          <div className="form-floating">
            <input type="text" className="form-control" id="inputName" placeholder="Nome e Cognome" value={name} onChange={(e) => setName(e.target.value)} />
            <label htmlFor="inputName">Nome e Cognome</label>
          </div>
          <div className="form-floating">
            <input type="email" className="form-control" id="inputMail" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <label htmlFor="inputMail">Email</label>
          </div>
          <div className="form-floating">
            <input type="tel" className="form-control" id="inputPhone" placeholder="Telefono" value={phone} onChange={(e) => setPhone(e.target.value)} />
            <label htmlFor="inputPhone">Telefono</label>
          </div>
          <div className="form-floating">
            <textarea className="form-control" id="inputMessage" cols={30} rows={10} placeholder="Messaggio" value={message} onChange={(e) => setMessage(e.target.value)} />
            <label htmlFor="inputMessage">Messaggio</label>
          </div>

          <div className='submit_container'>
            <span className={'sent d-' + mailOK}>Messaggio inviato</span>
            <span className={'not-sent d-' + mailERR}>Messaggio non inviato</span>
            <button type='submit'>Invia</button>
          </div>

        </form>

      </div>
    </section>
  )
}

export default Contacts
