import React from 'react'
import './cover.css'
import Me from './../../assets/me.png'
import { AiOutlineLine } from 'react-icons/ai'

const Cover = () => {
    const title = `Soluzioni Web`;
    const title2 = `per il tuo business`;
    return (
        <section id="home" className="section container cover_container">
            <div className="cover_container_titles">
                <h1 data-aos="fade-down" data-aos-delay="100" className="title"> {title} </h1>
                <h1 data-aos="fade-down" className="title"> {title2} </h1>

                <span className="spacer"></span>

                <p data-aos="fade-up" data-aos-delay="300" className="paragraph">
                    <i>competenza. affidabilità. esperienza.</i>
                </p>
            </div>

            <div data-aos="fade-up" data-aos-delay="500" className="cover_container_avatar">
                <img src={Me} alt="" />
                <p>
                    <a href="#about"><AiOutlineLine /> Scroll down</a>
                </p>
            </div>
        </section>
    )
}

export default Cover
