import { React } from 'react'
import './about.css'
import { GoDownload } from 'react-icons/go'
import { HiOutlineArrowDownRight } from 'react-icons/hi2'
import { FaLinkedinIn, FaWhatsapp } from 'react-icons/fa'
import CV from './../../assets/CV.pdf'

const About = () => {
  return (
    <section id="about" className="section container about_container">
      <h1 data-aos="fade-up" className="subtitle" data-aos-offset="0">Chi sono</h1>

      <div data-aos="fade-up" data-aos-delay="50" data-aos-offset="0" className="about_description">
        <p>
          Ciao, mi chiamo Mauro e mi occupo da diversi anni di progettazione e realizzazione di siti internet professionali,
          che vanno dalla semplice vetrina alle complesse web app dinamiche e interattive.
        </p>
        <h5>La mia missione?</h5>
        <p>
          Aiutare i professionisti a curare la propria immagine online, sfruttando le potenzialità del web e realizzando uno spazio virtuale che racconti l’azienda in versione digitale.
        </p>
      </div>

      <div className="about_footer">
        <div data-aos="fade-up" data-aos-delay="550" className="about_downloads">
          <a href={CV} download="CV Mauro Caporaso" target="_blank" rel="noreferrer">
            Scarica CV <GoDownload />
          </a>
          {/* <a href={null}>
            Vai al portfolio <HiOutlineArrowDownRight />
          </a> */}
        </div>

        <div className="about_targets">
          <div data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease" className="about_target">
            <h1>10 +</h1>
            <p>Anni di esperienza nel settore.</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease" className="about_target">
            <h1>5</h1>
            <p>Lavori completati con successo.</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="500" data-aos-easing="ease" className="about_target">
            <h1>100 %</h1>
            <p>Recensioni positive dei clienti.</p>
          </div>
        </div>
      </div>

      <div data-aos="fade-up" data-aos-delay="550" data-aos-offset="0" data-aos-easing="ease" className="about_socials">
        <a target='_blank' href="https://www.linkedin.com/in/mauro-caporaso-21603a97/"><FaLinkedinIn /></a>
        <a target='_blank' href="https://wa.me/+393666970891"><FaWhatsapp /></a>
      </div>
    </section>
  )
}

export default About
