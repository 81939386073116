import React from 'react'
import './banner.css'
import skills_json from './skills.json'

const Banner = () => {
    return (
        <section className="container banner_container">
            <h1 data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease"className="subtitle">Competenze</h1>
            <div className="banner_list">
                {
                    skills_json.skills.map(({ key }, index) => {
                        return (
                            <div key={index} data-aos="fade-up" data-aos-delay={index*100} data-aos-offset="0" data-aos-easing="ease" className="banner_card" >
                                <img src={"skills/" + key + ".png"} alt="" />
                                <p>{key}</p>
                            </div>
                        )
                    })
                }
            </div>

        </section >
    )
}

export default Banner
