import React from 'react'
import './footer.css'
import { FaLinkedinIn, FaWhatsapp } from 'react-icons/fa'

const Footer = () => {
    return (
        <section className="container footer_container">
            <div className="footer_logo">
                <p>.mauro</p>
                <div className="footer_socials">
                    <a target='_blank' href="https://www.linkedin.com/in/mauro-caporaso-21603a97/"><FaLinkedinIn /></a>
                    <a target='_blank'href="https://wa.me/+393666970891"><FaWhatsapp /></a>
                </div>
            </div>
            <div className="footer_rights">
                <p>© Mauro Caporaso. All right reserved.</p>
            </div>
            <div className="footer_cred">
                <p>Designed by <a href="mailto:vitodig1999@libero.it"><strong>Vitodig</strong></a></p>
                <p>Developed by Mauro Caporaso</p>
            </div>
        </section>
    )
}

export default Footer
