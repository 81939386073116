import React from 'react'
import './testimonials.css'
import default_profile from './../../assets/profile.png'
import testimonials_json from './testimonials.json'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Testimonials = () => {
    return (
        <section id="testimonials" className="section">
            <h1 data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease" className="subtitle">Recensioni</h1>
            <Swiper className='testimonials_container testimonials_list' data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease"
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={50}
                slidesPerView={1}>
                {
                    testimonials_json.testimonials.map(({ name, avatar, service, body }, index) => {
                        return (
                            <SwiperSlide key={index} className="testimonial">
                                <div className="testimonial_header">
                                    <img src={avatar.length > 0 ? "testimonials/" + avatar : default_profile} alt="" />
                                    <h5>{name}
                                        <small>{service}</small>
                                    </h5>
                                </div>
                                <div className="testimonial_body">
                                    <p>{body}</p>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>

        </section>
    )
}

export default Testimonials



